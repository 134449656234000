import { SearchBox } from '@elastic/react-search-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'

const SearchContainer = ({searchTerm, setSearchTerm, t}) => {
    return (
        <div className='qo-search-cnt'>
            <div className='qo-search-header-cnt'>
                <span className='qo-search-header-txt'>{t('search')}</span>
                <div className='qo-search-box-cnt'>
                    <SearchBox className='qo-searchBox' autocompleteSuggestions={true} inputProps={{placeholder: t("search")}} />
                    <FontAwesomeIcon className='qo-search-icon' icon={faMagnifyingGlass} />
                    {
                        searchTerm?.trim() &&
                        <FontAwesomeIcon className='qo-clear-icon' icon={faXmark} 
                            onClick={()=>setSearchTerm('')}
                        />
                    }
                    
                </div>
            </div>
            <p className="qo-search-result-txt">{t("search_results_for")} "{searchTerm}"</p>
        </div>
    )
}

export default SearchContainer