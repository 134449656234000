import { ClickAwayListener, Tooltip, styled, tooltipClasses } from '@mui/material'
import React, { useState } from 'react'

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#fbfbfb",
        "&::before": {
            border: "1px solid #cfcfcf"
        },
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fbfbfb",
        color: "#34383c",
        border: "1px solid #cfcfcf",
        fontSize: 16,
        fontFamily: "Grtsk-QuidelOrtho-V",
        padding: "10px",
        marginBottom: "4px !important"
    },
}));

const TooltipClick = ({ title, children }) => {

    const [openTooltip, setOpenTooltip] = useState(false);

    const handleTooltipOpen = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenTooltip(prev => !prev);
    }

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    }

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <CustomTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    // onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={title}
                    arrow
                    placement="top"
                >
                    <div className='qo-tooltip-div' onClick={handleTooltipOpen}>{children}</div>
                </CustomTooltip>
            </div>
        </ClickAwayListener>
    )
}

export default TooltipClick