import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import TooltipClick from './TooltipClick'
import { getConfig } from '../config/config-helper'

const ResultView = ({ result, trackClickThrough, sortDirection }) => {

    const tag = "tags"

    const joinElements = (resultObj, selector) => {
        return (
            typeof (resultObj[selector]?.raw) === "object" ?
                (sortDirection === "desc" ? resultObj[selector]?.raw?.reverse()?.join(', ') : resultObj[selector]?.raw?.sort()?.join(', '))
                :
                resultObj[selector]?.raw
        )
    }

    const preventClick = (e) => {
        e.preventDefault();
        return false
    }
    const {headerFooterDomain} = getConfig();
    

    // String.prototype.replaceJSX = function (find, replace) {
    //     return this.split(find).flatMap((item) => [item, replace]).slice(0, -1);
    // }

    return (
        <a href={result?.url?.raw} className='qo-result-card' onClick={preventClick}>
        <div 
            onClick={() => {

                trackClickThrough(result?.id?.raw,['QO_result_click']);
                
                const {origin} = new URL(result?.url?.raw);
                if(headerFooterDomain === `${origin}/`){
                    window.open(`${result?.url?.raw}`, "_self");
                }else{
                    window.open(`${result?.url?.raw}`, "_blank");
                }
                
            }}
        >
            <div className='qo-result-card-title'>
                <TooltipClick
                    title={joinElements(result, 'category')}
                >
                    {
                        joinElements(result, 'category')
                    }
                </TooltipClick>
            </div>
            <p className='qo-result-card-body' dangerouslySetInnerHTML={{__html: result?.body_content?.snippet?.replace(/em>/g, 'mark>')}}></p>
            <div className='qo-result-card-tag'>
                <TooltipClick
                    title={joinElements(result, tag)}
                >
                    {
                        joinElements(result, tag)
                    }
                </TooltipClick>
                {
                    result[tag]?.raw?.length > 0 &&
                    result[tag]?.raw[0].trim() &&
                    <FontAwesomeIcon className='qo-icon fs-16' style={{ marginLeft: "6px" }} icon={faAngleRight} />
                }
            </div>
        </div>
        </a>
    )
}

export default ResultView