import React, { useEffect, useState } from 'react'
import { getConfig } from '../config/config-helper';

const Header = (props) => {

    const {country, language } = props;

    const [header, setHeader] = useState('');
    const [showHeaderLoader, setShowHeaderLoader] = useState(true);

    const {cssScriptKey, headerFooterDomain, searchDomain, headerApi, jsHeaderFooterScriptKey, jsLibraryScriptKey} = getConfig();


    useEffect(() => {

        
        

        const getScriptTag = async () => {

            const cssLinkArray = await getCssLink();

            for(let i=0; i< cssLinkArray?.length; i++){
                const csslinkList = cssLinkArray[i]?.split('//')[1].split('/');
                csslinkList && csslinkList.shift()
    
    
                const cssLinkTag = document.createElement("link");
                cssLinkTag.setAttribute("rel", "stylesheet")
                cssLinkTag.setAttribute("type", "text/css")
                cssLinkTag.href = headerFooterDomain + csslinkList?.join('/');
    
    
                document.head.insertBefore(cssLinkTag, document.head.firstChild)
            }
            

            const jsFullSrcArray = await getHeader();

            for(let i=0; i< jsFullSrcArray?.length; i++){
                const jsFullSrcList = jsFullSrcArray[i]?.split('//')[1].split('/');
                jsFullSrcList && jsFullSrcList.shift()

                const jsScript = document.createElement("script");
                jsScript.src = headerFooterDomain + jsFullSrcList?.join('/');


                document.body.appendChild(jsScript);
            }
            

        }

        getScriptTag()
    }, []);

    const getCssLink = async () => {
        var tsTime = new Date(new Date().getTime());

        let cssTagHref = await fetch(headerApi + `${language}-${country}&ts=` + tsTime)
            .then(response => response.text())
            .then(data => {
                const enc1 = data;
                const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');

                let staticLinks = []
                let links = newDocument.getElementsByTagName("link");
                for (let i = 0; i < links.length; i++) {
                    if (links[i].href.indexOf(jsHeaderFooterScriptKey) !== -1 || links[i].href.indexOf(jsLibraryScriptKey) !== -1) {
                        staticLinks.push(links[i].href)
                    }
                }

                return staticLinks

            }).catch((error) => {
                console.log("error occurred while fetching link");
                return ''
            });
        return cssTagHref
    }


    const getHeader = async () => {
        var tsTime = new Date(new Date().getTime());
        setShowHeaderLoader(true);

        let jsTagSrc = await fetch(headerApi + `${language}-${country}&ts=` + tsTime)
            .then(response => response.text())
            .then(data => {
                const enc1 = data;
                const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');

                var imgs = newDocument.getElementsByTagName("img");

                for (let i = 0; i < imgs.length; i++) {
                    if ((imgs[i].src.indexOf("http://localhost") !== -1) || (imgs[i].src.indexOf(searchDomain) !== -1)) {
                        let oldHeaderLogoSRC = imgs[i].src.split('//')[1].split('/');
                        oldHeaderLogoSRC.shift();
                        imgs[i].src = headerFooterDomain + oldHeaderLogoSRC.join('/');
                        if(imgs[i].srcset) imgs[i].srcset = "";
                    }
                }
                var a = newDocument.getElementsByTagName("a");

                for (let i = 0; i < a.length; i++) {
                    if ((a[i].href.indexOf("http://localhost") !== -1) || (a[i].href.indexOf(searchDomain) !== -1)) {
                        let oldHeaderLogohref = a[i].href.split('//')[1].split('/');
                        oldHeaderLogohref.shift();
                        a[i].href = headerFooterDomain + oldHeaderLogohref.join('/');
                    }
                }

                const converted = newDocument.body.innerHTML;

                setHeader(converted);

                let staticLinks = []


                let scripts = newDocument.getElementsByTagName("script");

                for (let i = 0; i < scripts.length; i++) {
                    if (scripts[i].src.indexOf(jsHeaderFooterScriptKey) !== -1 || scripts[i].src.indexOf(jsLibraryScriptKey) !== -1) {
                        staticLinks.push(scripts[i].src)
                    }
                }
                setShowHeaderLoader(false);
                
                return staticLinks

            }).catch((error) => {
                console.log("error occurred while fetching header");
                setShowHeaderLoader(false);
                return ''
            });
        return jsTagSrc
    }

    return (
        <div>
            {

                showHeaderLoader ?
                    <div className="headerPlaceholder">Loading...</div>
                    :
                    <div id="header" className="xf-web-container" dangerouslySetInnerHTML={{ __html: header }}></div>

            }
        </div>
    )
}

export default React.memo(Header)