import React, { useEffect, useState } from 'react'
import { getConfig } from '../config/config-helper';

const Footer = (props) => {

    const { country, language } = props;

    const [footer, setFooter] = useState('');
    const [showFooterLoader, setShowFooterLoader] = useState(true);

    const {headerFooterDomain, searchDomain, footerApi} = getConfig();

    useEffect(() => {

        getFooter();

    }, [])



    const getFooter = async () => {

        setShowFooterLoader(true);
        var tsTime = new Date(new Date().getTime());
        fetch(footerApi + `${language}-${country}&ts=` + tsTime)
            .then(response => response.text())
            .then(data => {
                const enc2 = data;
                const newDocument2 = (new DOMParser()).parseFromString(enc2, 'text/html');

                var aTags = newDocument2.body.querySelectorAll('.footer-hyperlink-section a');

                // console.log("atags", aTags);

                for (let i = 0; i < aTags.length; i++) {
                    if(aTags[i].href && (aTags[i].href.indexOf("http://localhost") !== -1) || (aTags[i].href.indexOf(searchDomain) !== -1)){
                        let oldFooterLogoHref = aTags[i].href?.split('//')[1].split('/');
                        oldFooterLogoHref && oldFooterLogoHref.shift();
                        aTags[i].href = headerFooterDomain + oldFooterLogoHref?.join('/');
                    }
                }

                var imgs = newDocument2.getElementsByTagName("img");

                for (let i = 0; i < imgs.length; i++) {
                    if((imgs[i].src.indexOf("http://localhost") !== -1) || (imgs[i].src.indexOf(searchDomain) !== -1)){
                        let oldFooterLogoSRC = imgs[i].src.split('//')[1].split('/');
                        oldFooterLogoSRC.shift();
                        imgs[i].src = headerFooterDomain + oldFooterLogoSRC.join('/');
                    }
                }

                var sources = newDocument2.getElementsByTagName("source");

                for (let i = 0; i < sources.length; i++) {
                    if((sources[i].srcset.indexOf("http://localhost") !== -1) || (sources[i].srcset.indexOf(searchDomain) !== -1)){
                        sources[i].srcset = headerFooterDomain + sources[i].srcset;
                    }
                }




                const converted2 = newDocument2.body.innerHTML;
                setFooter(converted2);
                setShowFooterLoader(false);

            }).catch((error) => {
                console.log("error occurred while fetching footer");
                setShowFooterLoader(false);
                return ''
            })

    }

    return (
        <div>
            {
                showFooterLoader ?
                    <div className="footerPlaceholder">Loading...</div>
                    :
                    <div id="footer" className="xf-web-container" dangerouslySetInnerHTML={{ __html: footer }}></div>

            }
        </div>
    )
}

export default Footer