import React from 'react'
import FilterBlock from './FilterBlock'

const FilterBlocksContainer = ({ filters, removeFilter }) => {

    const unwindedArray = filters.reduce((acc, current) => {
        const unwindedArraySingle = current?.values?.map(value => {
            return {
                field: current?.field,
                value
            }
        })
        return [...acc, ...unwindedArraySingle]
    }, [])



    return (
        <>
            {
                unwindedArray?.length > 0 ?
                    <div className='qo-filter-block-cnt'>
                        {
                            unwindedArray?.map(obj => {
                                return (
                                    <FilterBlock
                                        key={obj.value}
                                        filterObj={obj}
                                        removeFilter={removeFilter}
                                    />
                                )
                            })
                        }
                    </div>
                    :
                    <div></div>
            }
        </>

    )
}

export default FilterBlocksContainer