import React from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./css/global.css"

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from "./config/config-helper";
import SearchLayout from "./components/SearchLayout";
import LoadingSpinner from "./components/LoadingSpinner";
import Header from "./components/Header";
import Footer from "./components/Footer";

import "./components/I18Next";

const { hostIdentifier, searchKey, endpointBase, engineName, subEngines } = getConfig();

const appPathName = window.location.pathname;
const country = appPathName?.split('/')[1]
const language = appPathName?.split('/')[2]
const currentEngineName = subEngines[!!language && `${country}-${language}`]


const connector = new AppSearchAPIConnector({
  searchKey,
  engineName: currentEngineName || "gmc-global-en-marketing",
  hostIdentifier,
  endpointBase,
  beforeSearchCall: (existingSearchOptions, next) => {
    return next({
      ...existingSearchOptions,
      analytics: {
        tags: ["QO_initial_search"]
      }
    })
  }
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};

export default function App() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={(props) => (props)}>
        {(props) => {
          const { wasSearched, clearFilters, results, searchTerm, setSearchTerm, removeFilter, isLoading, filters, trackClickThrough } = props
          return (
            <div
              id={currentEngineName === "gmc-ru-ru-marketing" ? "font-fmly-russian-only" : ""}
              className="app-cnt"
            >
              <ErrorBoundary>

                <LoadingSpinner
                  open={isLoading}
                />

                {
                  currentEngineName ?
                    <>
                      <Header
                        country={country}
                        language={language}
                      />

                      <SearchLayout
                        searchParams={{ wasSearched, clearFilters, results, searchTerm, setSearchTerm, removeFilter, filters, trackClickThrough }}
                        country={country}
                        language={language}
                      />

                      <Footer
                        country={country}
                        language={language}
                      />
                    </>
                    :
                    <div className="qo-error-msg">The country and the language specified doesn't exist</div>
                }


              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
