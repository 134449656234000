import React, { useEffect, useRef, useState } from 'react'
import { Auth, Hub } from 'aws-amplify';
import { getConfig } from '../config/config-helper';
import { configureAmplify } from '../services';

configureAmplify();

const { Auth_FederatedSignIn_Provider } = getConfig();

const Authenticate = ({ children }) => {

    const [isNotQuideOrtho, setIsNotQuideOrtho] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);


    const loginBtn = useRef();

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "customOAuthState":
                    window.location.href = data;
                    break;
                default:
                    return;
            }
        });

        Auth.currentAuthenticatedUser()
            .then(user => {
                // const userDomain = user?.attributes?.email?.split("@")?.pop();
                const userType = user?.attributes['custom:UserType'];

                console.log("user", userType)

                setIsAuthorized(true);
                // if ( ![domain].includes(userDomain) ) {
                //   setIsNotQuideOrtho(true);
                // }

                if (userType !== 'E') {
                    setIsNotQuideOrtho(true);
                }

            })
            .catch((e) => {
                console.log("Error in Authentication");
                console.error(e);
                loginBtn.current.click();
            });

        return unsubscribe;
    }, [])

    return (
        <div className='app-cnt'>

            {
                isNotQuideOrtho ?
                    <h1>Does not belong to QuideOrtho Employee. Please check you login credentials.</h1>
                    :
                    isAuthorized ?
                        <div>
                            {children}
                        </div>
                        :
                        <div>
                            <div><h2>Logging in. Please wait...</h2></div>
                            <button
                                ref={loginBtn}
                                style={{ display: 'none' }}
                                onClick={() =>
                                    Auth.federatedSignIn({ "provider": Auth_FederatedSignIn_Provider, "customState": window.location.href })
                                        .then(result => {
                                            console.log("Authentication federatedSignIn, data- " + JSON.stringify(result));
                                        })
                                        .catch(err => {
                                            console.error("Authentication federatedSignIn, error- " + err);
                                        })
                                }
                            >
                                Login
                            </button>
                        </div>
            }

        </div>
    )
}

export default Authenticate