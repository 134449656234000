import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Results,
    PagingInfo,
    Paging,
    Sorting
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import {
    buildSortOptionsFromConfig,
    getConfig,
    getFacetFields
} from "./../config/config-helper";
import FacetView from './FacetView';
import ResultView from './ResultView';
import SearchContainer from './SearchContainer';
import FacetContainer from './FacetContainer';
import FilterBlocksContainer from './FilterBlocksContainer';

const SearchLayout = (props) => {

    const { wasSearched, clearFilters, results, searchTerm, setSearchTerm, removeFilter, filters, trackClickThrough } = props.searchParams
    const { language, country } = props;
    const { t, i18n } = useTranslation();
    const { languageWithCountry, languageWithoutCountry} = getConfig();

    // console.log("results", results);

    const [sortDirection, setSortDirection] = useState('');


    const currentLanguage = languageWithCountry[language+'_'+country] || languageWithoutCountry[language];

    useEffect(()=>{
        i18n.changeLanguage(currentLanguage || "en");
    },[])

    const handlePageChange = (onChange, ...args) => {
        onChange(...args);
        window.scrollTo({top:0, behavior:"smooth"})
      }

    const handleSorting = (onChange, value, ...args) => {
        onChange(value, ...args);
        console.log("value", value);
        setSortDirection(value?.split("|||").pop());
    }

    return (
        <>
            <Layout
                header={
                    <SearchContainer
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        t={t}
                    />
                }
                sideContent={
                    <FacetContainer
                        clearFilters={clearFilters}
                        t={t}
                    />
                }
                bodyContent={
                    <>
                        <FilterBlocksContainer
                            filters={filters}
                            removeFilter={removeFilter}
                        />
                        <div className='qo-result-cnt'>
                            {
                                results?.length === 0 ? 
                                <h3 className='qo-no-result-msg'>{t("no_result_msg")}</h3>
                                :
                                results?.map(result => {
                                    return (
                                        <Results
                                            key={result?.id?.raw}
                                            titleField={getConfig().titleField}
                                            urlField={getConfig().urlField}
                                            thumbnailField={getConfig().thumbnailField}
                                            shouldTrackClickThrough={true}
                                            view={() => {
                                                return (
                                                    <ResultView
                                                        result={result}
                                                        trackClickThrough={trackClickThrough}
                                                        sortDirection={sortDirection}
                                                    />
                                                )
                                            }}
                                        />
                                    )
                                })
                            }

                        </div>
                    </>
                }
                bodyHeader={
                    <React.Fragment>
                        {wasSearched && <PagingInfo
                            view={(props) => {
                                const { start, end, totalResults } = props
                                return <div className='qo-page-info'>{`${t("showing")} ${start}-${end} ${t("of")} ${totalResults} ${t("results")}`}</div>
                            }}
                        />}
                        {/* {wasSearched && <ResultsPerPage />} */}
                        {wasSearched && (
                            <Sorting
                                label={""}
                                sortOptions={buildSortOptionsFromConfig(t)}
                                view={(props)=>{
                                    return <Sorting
                                                label={""}
                                                sortOptions={buildSortOptionsFromConfig(t)}
                                                onChange={(value, ...args)=>handleSorting(props.onChange, value, ...args)}
                                            />
                                }}
                            />
                        )}
                    </React.Fragment>
                }
                bodyFooter={
                    <div className='qo-pagination-cnt'>
                    <Paging showLessItems={true} 
                        view={(props)=>{
                            return <Paging showLessItems={true} 
                                    onChange={(...args)=>handlePageChange(props.onChange, ...args)}
                                    />
                            }}
                    />
                    </div>
                }
            />
        </>
    )
}

export default SearchLayout