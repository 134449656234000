import { Facet } from "@elastic/react-search-ui";
import { getFacetFields } from "./../config/config-helper";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import FacetView from "./FacetView";

const FacetContainer = ({ clearFilters, t }) => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [showFilter, setShowFilter] = useState(false);


    useEffect(() => {
        const handleScreenSize = () => {
            setScreenSize(window.innerWidth)
        }

        window.addEventListener('resize', handleScreenSize);

        return () => {
            window.removeEventListener('resize', handleScreenSize);
        };

    })


    const handleClearFilter = () => {
        clearFilters();
    }

    const handleFilter = () => {
        setShowFilter(prev => !prev);
    }

    return (
        <>
            {
                screenSize < 800 &&
                <button className="toggle-filter-btn" onClick={handleFilter} >
                    {t("filter")} {t("results")}
                    <FontAwesomeIcon className='qo-icon fs-18' icon={faAngleDown} />
                </button>
            }

            <div className={`qo-facet-cnt ${showFilter ? 'pop-filter' : ''}`}>

                <div className='qo-side-heading-cnt filter-dsk'>

                    <div className='qo-side-heading'>{t("filters")}</div>

                    <div className='qo-side-clear' onClick={handleClearFilter}>{t("clear_filters")}</div>
                </div>

                <div className="qo-side-heading-cnt-mob filter-mob">
                    <FontAwesomeIcon onClick={handleFilter} className='qo-icon fs-20' icon={faAngleLeft} />
                    <p className='qo-side-heading'>{t("filters")}</p>
                    <div className='qo-side-clear' onClick={handleClearFilter}>{t("clear")}</div>
                </div>
                <hr className="qo-hr-line"></hr>

                {getFacetFields().map(field => (
                    <Facet key={field} field={field} label={t(field)}
                        view={(props) => {
                            return (
                                <FacetView
                                    facets={props}
                                    t={t}
                                />
                            )
                        }}
                    />
                ))}

                <div className="filter-result-cnt filter-mob">
                        <button className="filter-result-btn" onClick={handleFilter}>{t("filter")} {t("results")}</button>
                </div>
            </div>
        </>
    )
}

export default FacetContainer