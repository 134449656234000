import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'


const FacetView = (props) => {

    const {t} = props;
    // let label = props.facets.label?.replace('_e','');
    let label = props.facets.label;

    const isFacetPresent = (options) => {
        if (options?.length < 1) {
            return false
        } else if (options?.length === 1) {
            return options[0]?.value?.trim()
        } else {
            return true
        }
    }

    return (
        <div>
            {
                isFacetPresent(props?.facets?.options) &&

                <>
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon className='qo-icon fs-18' icon={faAngleDown} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className='qo-filter-accordian'
                        >
                            <h3 className="qo-filter-heading">{t(label)}</h3>
                        </AccordionSummary>
                        <AccordionDetails className='qo-filter-accordian-details'>
                            <div className="qo-all-filter">

                                {
                                    props?.facets?.options?.map(option => {

                                        return (
                                            option?.value?.trim() &&
                                            <div key={option.value} className='qo-filter-container'>
                                                <input type='checkbox'
                                                    checked={option.selected}
                                                    className="qo-filter-input"
                                                    onChange={(e) => {
                                                        e.target.checked ? props.facets.onSelect(option?.value) : props.facets.onRemove(option?.value)
                                                    }}
                                                />

                                                <p className="qo-filter-text">
                                                    {option?.value} ({option?.count})
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                                {props.facets.showMore && <div className="qo-facet-more" onClick={props.facets.onMoreClick}>+ {t("more")}</div>}
                            </div>
                        </AccordionDetails>
                    </Accordion>



                    <hr className="qo-hr-line"></hr>
                </>
            }
        </div>
    )
}

export default FacetView