import React from 'react'
import { getConfig } from '../config/config-helper';
import App from '../App';
import Authenticate from './Authenticate';

const { App_ENV } = getConfig();
const IsAuthRequired = () => {
    return (
        <div>
            {
                ["PROD", "QA", "DEV"].includes(App_ENV) ?
                    <Authenticate>
                        <App />
                    </Authenticate>
                    :
                    <App />
            }
        </div>
    )
}

export default IsAuthRequired