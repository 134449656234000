import { Amplify } from 'aws-amplify';

import { getConfig } from './config/config-helper'

const { Auth_region, Auth_userPoolWebClientId, Auth_userPoolId, Auth_oauth_domain, Auth_redirectSignIn, Auth_redirectSignOut, Auth_responseType, Auth_oauth_identity_id } = getConfig();

export const awsConfig = {

    Auth: {
        region: Auth_region, 
        userPoolWebClientId: Auth_userPoolWebClientId,
        userPoolId: Auth_userPoolId,
        mandatorySignIn: true,
        "oauth": {
            "domain": Auth_oauth_domain,
            "scope": [
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ],
            "redirectSignIn": Auth_redirectSignIn,
            "redirectSignOut": Auth_redirectSignOut,
            "responseType": Auth_responseType,
            "identity_id": Auth_oauth_identity_id
        }
    }
};

export function configureAmplify() {

    Amplify.configure(awsConfig)

}