import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const FilterBlock = ({ filterObj, removeFilter }) => {

  const handleRemoveFilter = (obj) => {
    removeFilter(obj.field, obj.value);
  }

  return (
    <div className='qo-filter-block'>
      {filterObj?.value}
      <FontAwesomeIcon
        onClick={() => { handleRemoveFilter(filterObj) }}
        className='qo-icon-indigo fs-12'
        style={{ marginLeft: "6px", cursor: "pointer" }}
        icon={faXmark}
      />
    </div>
  )
}

export default FilterBlock