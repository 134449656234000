import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en_translation from '../assets/translation/en_translation.json'
import fr_ca_translation from '../assets/translation/fr_ca_translation.json'
import pt_translation from '../assets/translation/pt_translation.json'
import es_latam_translation from '../assets/translation/es_latam_translation.json'
import zh_translation from '../assets/translation/zh_translation.json'
import ja_translation from '../assets/translation/ja_translation.json'
import ko_translation from '../assets/translation/ko_translation.json'
import de_translation from '../assets/translation/de_translation.json'
import es_translation from '../assets/translation/es_translation.json'
import fr_translation from '../assets/translation/fr_translation.json'
import it_translation from '../assets/translation/it_translation.json'
import ru_translation from '../assets/translation/ru_translation.json'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            "en": {translation: en_translation},     
            "fr_ca": {translation: fr_ca_translation},        
            "pt": {translation: pt_translation}, 
            "es_latam": {translation: es_latam_translation},
            "zh": {translation: zh_translation},
            "ja": {translation: ja_translation},
            "ko": {translation: ko_translation},
            "de": {translation: de_translation},
            "es": {translation: es_translation},
            "fr": {translation: fr_translation},
            "it": {translation: it_translation},
            "ru": {translation: ru_translation}
        }
    });

export default i18n;